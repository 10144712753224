<template>
  <section>
    <p class="text-h6 secondary--text">Reportes de proveedores</p>
    <v-row>
      <v-col cols="12" sm="12" md="4">
        <v-text-field label="No. de reporte" outlined
         v-model="filtro.no_proceso"
         @input="getReportesProveedorFilter()"
         clearable></v-text-field>
      </v-col>
      <v-col cols="12" sm="12" md="4">
        <v-select
          label="Estado"
          outlined
          no-data-text="Sin datos"
          :items="estados"
          item-value="id"
          item-text="nombre"
          v-model="filtro.estado"
          @change="getReportesProveedorFilter()"
          clearable
        ></v-select>
      </v-col>
      <v-col cols="12" sm="12" md="4">
        <v-select
          label="Modalidad de compra"
          outlined
          no-data-text="Sin datos"
          :items="modalidades"
          item-value="id"
          item-text="nombre"
          v-model="filtro.modalidad_compra"
          @change="getReportesProveedorFilter()"
          clearable
        ></v-select>
      </v-col>
    </v-row>

    <data-table-component
      :headers="headers"
      :items="reportes"
      v-models:select="porPaginaModel"
      v-models:pagina="paginaModel"
      @paginar="paginar"
      :total_registros="filters.total_rows"
    >
      <template #[`item.created_at`]="{ item }">
        {{ moment(item.created_at).format("DD/MM/YYYY") }}
      </template>
      <template #[`item.estado_sancion.nombre`]="{ item }">
        <v-chip
         label
         dark
         :color="item.estado_sancion.nombre === 'Pendiente' ? '#9E9E9E' : 
         item.estado_sancion.nombre === 'Aprobado' ? 'success' : '#F44336'"
        >{{ item.estado_sancion.nombre }}</v-chip>
      </template>
      <template #[`item.acciones`]="{ item }">
        <v-tooltip
          top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
             v-bind="attrs"
             v-on="on"
             icon
             @click="verSeguimiento(item)"
            >
              <v-icon>mdi-eye</v-icon>
            </v-btn>
          </template>
          <span>Ver seguimiento</span>
        </v-tooltip>
      </template>
    </data-table-component>
    <!-- footer -->
    <footer
      tile
      class="py-4 mt-8 d-flex flex-column flex-sm-row"
      color="bgMinsal"
    >
      <!-- boton que redirecciona a la vista anterior -->
      <v-btn
        class="button-extra text-capitalize mt-4 mt-sm-0"
        color="secondary"
        outlined
        @click="$router.back()"
      >
        Volver
      </v-btn>
    </footer>
  </section>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import DataTableComponent from "../../components/DataTableComponent.vue";
export default {
  components: { DataTableComponent },
  data: () => ({
    estados: [
      {
        id: 1,
        nombre: "Pendientes"
      },
      {
        id: 2,
        nombre: "Aprobados"
      },
      {
        id: 3,
        nombre: "Rechazados"
      }
    ],
    modalidades: [],
    headers: [
      {
        text: "Código",
        value: "codigo",
      },
      {
        text: "Proceso",
        value: "contrado_orden.nombre_contrato",
      },
      {
        text: "Proveedor",
        value: "proveedor.nombre",
      },
      {
        text: "Fecha creación",
        value: "created_at",
      },
      {
        text: "Estado",
        value: "estado_sancion.nombre",
      },
      {
        text: "Acciones",
        value: "acciones",
        sortable: false,
        align:"center"
      },
    ],
    filtro:{
      no_proceso:null,
      estado:null,
      modalidad_compra:null,
      page:1,
      per_page:10
    }
  }),
  computed: {
    ...mapState("reporteProveedor", ["reportes", "filters"]),
    porPaginaModel: {
      get() {
        return this.filters.per_page;
      },
      set(value) {
        this.setFiltroPorPagina(value);
      },
    },
    paginaModel: {
      get() {
        return this.filters.page;
      },
      set(value) {
        this.setFiltroPage(value);
      },
    },
  },
  methods: {
    ...mapMutations("reporteProveedor", ["setFilters", "setContrato"]),
    ...mapActions("reporteProveedor", ["getReportesProveedor"]),
    paginar(filtros) {
      const { cantidad_por_pagina, pagina } = filtros;
      this.filters.page = pagina;
      this.filters.per_page = cantidad_por_pagina;
      this.getReportesProveedor(filtros);
    },
    async getModalidades() {
      const modalidad = await this.services.Paac.getModalidades();
      this.modalidades = modalidad.data.forma_contratacion;
    },
    async getEstados() {},
    verSeguimiento(item){

      let re = {}
      re.idContrato = item.contrado_orden.id
      
      this.setContrato(re)
      this.$router.push({
        name: "seguimiento-proveedor",
        params: {
          id_reporte_sancion: item.id,
        },
      });

    },
    getReportesProveedorFilter(){
      
      this.setFilters(this.filtro)
      this.getReportesProveedor(this.filtro)
    }
  },
  created() {
    this.getModalidades();
    this.getEstados();
    this.getReportesProveedor();
  },
};
</script>

<style></style>
